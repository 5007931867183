import type { CountryCode } from '@fifteen/design-system-vue';
import type { ISOLocale } from '@fifteen/shared-lib';

export const locales: ISOLocale[] = ['fr', 'en', 'de', 'es', 'it', 'pl'];
export const fallbackLocale = 'en';

// TODO find a way to get this from the CMS
export const guidesLocales = ['fr', 'en', 'de', 'es'];
export const blogPostsLocales = ['fr', 'en', 'de', 'es'];

export const localesToCountryCode: Record<string, CountryCode> = {
  fr: 'FR',
  en: 'GB',
  de: 'DE',
  es: 'ES',
  it: 'IT',
  pl: 'PL',
};
